<template>
    <span>
        <div class="card card-settings">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">Subscriptions</div>
                    <div v-if="isAccountAdmin" class="col-6 pad-right">
                        <a 
                            type="button" 
                            class="btn btn-primary"
                            :href="stripeSessionUrl"
                            target="_blank"
                        >
                            <span v-if="processing">
                                <i class="fal fa-lg fa-spinner"></i>
                            </span>
                            <span v-else>Manage Subscriptions</span>
                        </a>
                    </div>
                    <div v-else  class="col-6 pad-right"></div>
                </div>
            </div>
            <div class="card-body">
                <div class="section-content">
                    <div v-if="! isAccountAdmin" class="row">
                        <h5 class="col-md-12 pad-left" v-html="activePlan.account_contact_referral"></h5>
                    </div>
                    <div v-else class="row table-responsive" >
                        <div class="col-md-12">
                        <table class="table report-table with-bottom-border">
                            <thead>
                                <tr>
                                    <th class="width-30 "></th>
                                    <th class="width-30"></th>
                                    <th class="width-30"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td col-span="3" class="report-sub-group">Current Subscription</td>
                                </tr>
                                <tr>
                                    <td class="pad-left">Plan Description:</td>
                                    <td class="pad-left">{{activePlan.description}}</td>
                                    <td class="pad-left"></td>
                                </tr>
                                <tr>
                                    <td class="pad-left">Start Date:</td>
                                    <td class="pad-left">{{activePlan.start_date}}</td>
                                    <td class="pad-left"></td>
                                </tr>
                                <tr>
                                    <td class="pad-left">Number of Subscribers:</td>
                                    <td class="pad-left">{{activePlan.quantity}}</td>
                                    <td class="pad-left"><a href="">Update</a></td>
                                </tr>
                                <template v-if="activePlan.cancel_at_period_end">
                                    <tr>
                                        <td class="pad-left">End Date:</td>
                                        <td class="pad-left">{{activePlan.current_period_end}}</td>
                                        <td class="pad-left"><a href="">Reactivate</a></td>
                                    </tr>
                                    <tr v-if="activePlan.cancel_at_period_end">
                                        <td class="pad-left">Cancellation Date:</td>
                                        <td class="pad-left">{{activePlan.cancelled_at}}</td>
                                        <td class="pad-left"></td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr v-if="! activePlan.cancel_at_period_end">
                                        <td class="pad-left">Renews On:</td>
                                        <td class="pad-left">{{activePlan.current_period_end}}</td>
                                        <td class="pad-left"><a href="">Cancel</a></td>
                                    </tr>
                                    <tr>
                                        <td class="pad-left">Payment Method:</td>
                                        <td class="pad-left">{{ activePlanPaymentMethod }}</td>
                                        <td class="pad-left"><a href="">Update</a></td>
                                    </tr>
                                    <tr>
                                        <td class="pad-left">Last Invoice:</td>
                                        <td class="pad-left" v-html="viewInvoice"></td>
                                        <td class="pad-left" v-html="downloadInvoice"></td>
                                    </tr>
                                </template>
                                <tr>
                                    <td col-span="3" class="report-sub-group">Active Subscribers</td>
                                </tr>
                                <template v-for="(user, key) in activeUsers">
                                    <tr>
                                        <td class="pad-left">{{ user.name }}</td>
                                        <td class="pad-left">{{ user.email }}</td>
                                        <td class="pad-left">
                                            <button 
                                                class="btn pad-left"
                                                @click="toggleUserEdits(key)"
                                            >
                                            <i 
                                                v-if="key != userEdits.id"
                                                class="fal fa-lg fa-edit text-primary text-center"
                                                title="Edit"
                                            ></i>
                                            <i
                                                v-else
                                                class="fal fa-lg fa-edit text-secondary text-center"
                                                title="Cancel"
                                            ></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="key == userEdits.id" class="form-group">
                                        <td class="pad-right">Edit Name:</td>
                                        <td class="text-center">
                                            <input 
                                                type="text" 
                                                id="editUserName"
                                                v-model="userEdits.name"
                                                class="form-control"
                                                v-bind:class="{'is-invalid': userEdits.nameError.length > 0}"
                                            >
                                            <span role="alert" class="invalid-feedback">
                                                <strong>{{userEdits.nameError}}</strong>
                                            </span>
                                        </td>
                                        <td class="pad-left">
                                            <button 
                                                class="btn pad-left"
                                                @click="() => saveUserName()"
                                            >
                                                <i 
                                                    class="fal fa-lg fa-check text-success text-center"
                                                    title="Save"
                                                ></i>
                                            </button>
                                            <button 
                                                class="btn pad-left"
                                                @click="() => setUserEditsName()"
                                            >
                                                <i 
                                                    class="fal fa-lg fa-undo text-secondary text-center"
                                                    title="Cancel"
                                                ></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="key == userEdits.id" class="form-group">
                                        <td class="pad-right">Edit Email:</td>
                                        <td class="text-center">
                                            <input 
                                                type="text" 
                                                id="editUserEmail"
                                                v-model="userEdits.email"
                                                class="form-control"
                                                v-bind:class="{'is-invalid': userEdits.emailError.length > 0}"
                                            >
                                            <span role="alert" class="invalid-feedback">
                                                <strong>{{userEdits.emailError}}</strong>
                                            </span>
                                        </td>
                                        <td class="pad-left">
                                            <button 
                                                class="btn pad-left"
                                                @click="() => saveUserEmail()"
                                            >
                                                <i 
                                                    class="fal fa-lg fa-check text-success text-center"
                                                    title="Save"
                                                ></i>
                                            </button>
                                            <button 
                                                class="btn pad-left"
                                                @click="() => setUserEditsEmail()"
                                            >
                                                <i 
                                                    class="fal fa-lg fa-undo text-secondary text-center"
                                                    title="Cancel"
                                                ></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    export default {
        props: {
            urbicsUser: { Object, required: true },
        },
        data() {
            return {
                alert: Object(),
                hasAlert: false,
                processing: false,
                stripeSessionUrl: '',
                subs: Object(),
                subUsers: Object(),
                userEdits: {
                    id: 0,
                    name: '',
                    email: '',
                    nameError: '',
                    emailError: '',
                },
            }
        },
        created() {
            this.alert = Object();
            this.createCustomerPortalSession();
            this.subscriptionsAndUsers();
        },
        watch: {
            alert (newAlert, oldAlert) {
                this.hasAlert = Object.keys(newAlert).length > 0;
            },
        },
        computed: {
            activePlan() {
                if (this.subs.active === undefined) {
                    return {};
                }
                return this.subs.active.plan[Object.keys(this.subs.active.plan)[0]];
            },
            activeUsers() {
                if (this.subUsers.activated === undefined) {
                    return {};
                }
                return this.subUsers.activated;
            },
            isAccountAdmin() {
                return this.urbicsUser.isAccountAdmin;
            },
            activePlanPaymentMethod() {
                if (this.activePlan.collection_method == 'charge_automatically') {
                    return 'Credit Card (' + this.activePlan.pm_last_four + ')';
                }
                return 'Invoice';
            },
            viewInvoice() {
                return '<a target="_blank" rel="noopener noreferrer" href="' + this.activePlan.hosted_invoice_url + '">View</a>';
            },
            downloadInvoice() {
                return '<a href="' + this.activePlan.invoice_pdf + '">Download</a>';
            },


            // accountAdminReferral() {
            //     if (!this.urbicsUser.accountContactName
            //         && !this.urbicsUser.accountContactEmail) {
            //         return "An account administrator has not yet been designated for this account.<br/>"
            //             + "Please contact us at <a href=\"mailto:info@urbics.com\">info@urbics.com</a> with any questions about the individual subscriptions on this account.";
            //     }
            //     if (!this.urbicsUser.accountContactEmail) {
            //         return "Your account administrator, " + this.urbicsUser.accountContactName
            //             + ", can view and change the individuals subscribed to this account."
            //     }

            //     return "Your account administrator, " 
            //         + ((this.urbicsUser.accountContactName) 
            //             ? this.urbicsUser.accountContactName + " at " 
            //             : '')
            //         + '<a href="mailto:' + this.urbicsUser.accountContactEmail + '">'
            //         + this.urbicsUser.accountContactEmail + "</a>"
            //         + ", can view and change the individuals subscribed to this account."
            // },
        },
        methods: {
            createCustomerPortalSession() {
                this.processing = true;
                axios.get('/billing-portal')
                .then(response => {
                    this.processing = false;
                    this.stripeSessionUrl = response.data;
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to link to the billing portal at the momemt.  Refresh this page and try again', 
                        detail: error.message, 
                        key: 'stripeError'
                    });
                });
            },
            subscriptionsAndUsers() {
                this.hasSubs = false
                axios.get('/subscription/enterprise-detail-with-users')
                .then(response => {
                    if (response.data.length == 0) {
                        this.subs = {};
                        this.subUsers = {};
                    }
                    else {
                        this.subs = response.data.subs;
                        this.subUsers = response.data.subUsers;
                        this.hasSubs =  Object.keys(this.subs).length > 0;
                    }
                })
                .catch (error => {
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to retrieve subscription information. ', 
                        detail: error.message, 
                        key: 'stripeError'
                    });
                });
            },
            // subscriptionsAndUsers() {
            //     axios.get('/subscription/detail-with-users', {
            //         params: {
            //             id: this.urbicsUser.userId,
            //         }
            //     })
            //     .then(response => {
            //         if (response.data.length == 0) {
            //             this.subs = {};
            //             this.subUsers = {};
            //         }
            //         else {
            //             this.subs = response.data.subs;
            //             this.subUsers = response.data.subUsers;
            //         }
            //     })
            //     .catch (error => {
            //         this.alert = handleError({
            //             status: 'warning', 
            //             message: 'Unable to retrieve subscription information.', 
            //             detail: error.message, 
            //             key: 'stripeError'
            //         });
            //     });
            // },
            clearUserEdits() {
                this.userEdits.id = 0;
                this.userEdits.name = '';
                this.userEdits.email = '';
                this.userEdits.nameError = '';
                this.userEdits.emailError = '';
            },
            setUserEditsName() {
                this.userEdits.name = this.activeUsers[this.userEdits.id].name;
                this.userEdits.nameError = '';
            },
            setUserEditsEmail() {
                this.userEdits.email = this.activeUsers[this.userEdits.id].email;
                this.userEdits.emailError = '';
            },
            toggleUserEdits(userId) {
                let userEditsId = this.userEdits.id;
                this.clearUserEdits();
                if (this.activeUsers[userId] == undefined) {
                    return;
                }
                if (userEditsId == userId) {
                    return;
                }
                this.userEdits.id = userId;
                this.setUserEditsName();
                this.setUserEditsEmail();
                return;
            },
            saveUserName() {
                this.userEdits.nameError = '';
                if (this.userEdits.name.length < 6) {
                    this.userEdits.nameError = 'Must be at least six characters long';
                    return;
                }
                for (const [key, user] of Object.entries(this.activeUsers)) {
                    if (this.userEdits.name == user.name && this.userEdits.id != user.id) {
                        this.userEdits.nameError = 'This name is already in use.';
                        return;
                    }
                }
                this.updateUserName();
            },
            saveUserEmail() {
                this.userEdits.emailError = '';
                let regEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                if (! regEx.test(this.userEdits.email)) {
                    this.userEdits.emailError = 'Must be a valid email address.';
                    return;
                }
                for (const [key, user] of Object.entries(this.activeUsers)) {
                    if (this.userEdits.email == user.email && this.userEdits.id != user.id) {
                        this.userEdits.emailError = 'This email is already in use.';
                        return;
                    }
                }
                this.updateUserName();
            },
            updateUserName() {
                // Add call to update user
            },
            updateUserEmail() {
                // Add call to update user
            }
        },

        mounted() {
            if (process.env.NODE_ENV !== 'production') {
                console.log('Profile component mounted.')
            }
        },
    }
</script>


